import { Field, useFormikContext, ErrorMessage, Formik, Form } from 'formik';
import { useEffect, useState, useRef } from 'react';
import loading from '../../assets/images/loading-buffering.gif';
import {ReactComponent as ArrowSvg} from '../../assets/images/icons/arrow-up-solid.svg';
import {ReactComponent as CaretSvg} from '../../assets/images/icons/caret-down-solid.svg';
import {ReactComponent as LogoSvg} from '../../assets/images/amplify-loyalty-logo.svg';
import {ReactComponent as CheckSvg} from '../../assets/images/icons/check.svg';
import { SketchPicker } from 'react-color';
import axios from 'axios';
import { format, parseISO, parse } from 'date-fns';
import { toZonedTime, formatInTimeZone, fromZonedTime } from 'date-fns-tz';

export function Loading(props) {
  return (
    <div className={`loading-gif-container ${props.className}`}>
      <img className="loading-gif" src={loading} alt="loading" style={props.style}/>
    </div>
  )
}

export function Logo(props) {
  return (
    <>
      <LogoSvg className={`logo${props.className ? ` ${props.className}` : ''}`} />
    </>
  )
}

export const Arrow = (props) => {
  const {className, direction} = props;
  return (
    <div className={`arrow-icon${className ? ` ${className}` : ''}`}>
      <div className="arrow-icon__text">{direction === -1 ? 'Desc' : 'Asc'}</div>
      <ArrowSvg className={`arrow-icon__icon arrow-icon__icon--${direction === -1 ? 'desc' : 'asc'}`} />
    </div>
  )
}

export const Caret = (props) => {
  const {className, direction, action} = props;
  return (
    <div className={`caret-icon${className ? ` ${className}` : ''}`} onClick={() => {if (action) action()}}>
      <CaretSvg className={`caret-icon__icon caret-icon__icon--${direction && direction === 1 ? 'desc' : 'asc'}`} />
    </div>
  )
}

export const Check = (props) => {
  const {className} = props;
  return (
    <div className={`check-icon${className ? ` ${className}` : ''}`}>
      <CheckSvg />
    </div>
  )
}

// Components
export const CreateText = (props) => {
  const [text, setText] = useState([])
  const {chars, min, max, styles, classes} = props;
  
  const createText = (chars, min, max) => {
    const textContent = []
    for(let i = 0; i < chars; i++) {
      textContent.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }
    return textContent;
  }

  useEffect(() => {
    setText(createText(chars, min, max))
  }, [])
  
  return (
    <div className={`random-text ${classes ? classes : ""}`}>
      {text && 
        text.map((value, i) => {
          return (
            <div 
            key={i}
            className="text-bubble" 
            style={{
              backgroundImage: 'linear-gradient(to right, #F51504,#F8A305)',
              margin: '5px 15px 0 0',
              height: '5px',
              width: `${value}px`,
              ...styles,
              }}>
                {props.children}
            </div>
          )
      })}
      
    </div>
  )
}

export const CloseButton = (props) => {
  const {action, className} = props;

  return (
    <div className={`close-button ${className}`} onClick={() => action(false)}>
      x
    </div>
  )
}

// Functions
export const titleCaseText = (text) => {
  const capitalizeString = (str) => {
    return (`${str.slice(0,1).toUpperCase()}${str.slice(1).toLowerCase()}`)
  }
  const combineStrings = (arr) => {
    let newStr = "";
    arr.forEach(str => {
      newStr+=`${newStr !== "" ? " " : ""}${capitalizeString(str)}`
    });
    return newStr;
  }

  if (text.includes("_")) {
    return combineStrings(text.split("_"));
  } else if (text.includes(" ")) {
    return combineStrings(text.split(" "));
  } else if (text.includes("-")) {
    return combineStrings(text.split("-"));
  } else {
    return capitalizeString(text);
  }
}

// Forms
export const DatePicker = (props) => {
  const { 
    value,
    onChange,
    inputClass, 
    containerClass, 
    dateName, 
    submitting, 
    placeholder, 
    showCalendar, 
    spaceElement 
  } = props;
  let today = new Date();
  const [monthYear, setMonthYear] = useState([today.getMonth(), today.getFullYear()]);
  const [dates, setDates] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeDate, setActiveDate] = useState(new Date(value));
  const dpId = useRef(`dp-${Math.round(Math.random() * 1000)}`)

  const monthDays = [
    31,
    28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31
  ]

  const onClickFunc = (e) => {
    const menu = document.querySelector('.date-picker');
    if (!e.composedPath().includes(menu)) {
      document.removeEventListener('click', onClickFunc);
      setOpen(false);
    } 
  }

  useEffect(() => {
    if (open) setTimeout(() => {
      document.addEventListener('click', onClickFunc);
    }, 250);
  }, [open])

  useEffect(() => {
    getNewDates(monthYear);

  }, [monthYear])

  const getNewDates = ([month, year]) => {
    let monthDay = monthDays[month];
    let dateArr = [];
    let startDate = new Date(year, month, 1);
    let dayBuffer = startDate.getDay();
    if (dayBuffer !== 0) {
      for (let i = 0; i < dayBuffer; i++) {
        dateArr.push("");
      }
    }
    for (let i = 0; i < monthDay; i++) {
      dateArr.push(new Date(year, month, i+1));
    }
    setDates(dateArr);
  }

  const openCalendar = () => {
    if (!open) {
      setOpen(true);
    }
  }

  const closeCalendar = () => {
    setOpen(false);
  }

  return (
    <div id={dpId.current} className={`date-picker-container ${containerClass}`} onFocus={() => {openCalendar()}} >
      <div className="date-picker__input-container">
        {showCalendar !== false && <i className={`fa-solid fa-calendar-days date-picker__calendar`}></i>}
        <input className={`${inputClass} date-picker__input`} name={dateName} readOnly={true} disabled={submitting} placeholder={placeholder} value={value} />
      </div>
      {open &&
        <Calendar 
          dates={dates} 
          monthYear={monthYear} 
          activeDate={activeDate} 
          setMonthYear={setMonthYear}
          setActiveDate={setActiveDate}
          setFieldValue={onChange}
          closeCalendar={closeCalendar}
          dateName={dateName}
          containerClass={containerClass}
          spaceElement={spaceElement}
        />
      }
    </div>
  )
}

const Calendar = (props) => {
  const {
    dates, 
    monthYear, 
    activeDate, 
    setMonthYear, 
    setActiveDate, 
    setFieldValue, 
    closeCalendar,
    dateName,
    containerClass,
    spaceElement
  } = props;
  useEffect(() => {
    if (spaceElement){
      const button = document.querySelector(`.${containerClass}`);
      const container = document.querySelector(spaceElement);
      const popup = document.querySelector(".date-picker");
      
      const containerRect = container.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();
  
      const spaceBelow = containerRect.bottom - buttonRect.bottom;
      if (spaceBelow >= popup.offsetHeight) {
        // Position popup below button
        popup.style.top = `${buttonRect.bottom - containerRect.top}px`;
        popup.style.left = `${buttonRect.left}px`;
      } else {
        popup.style.top = `${buttonRect.top - popup.offsetHeight - 5}px`;
        popup.style.left = `${buttonRect.left}px`;
      }
    }
  }, [])
  const getMonthString = ([month, year]) => {
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
    return formatter.format(new Date(year, month));
  }

  const subtractMonth = () => {
    let month = monthYear[0] === 0 ? 11 : monthYear[0] - 1;
    let year = monthYear[0] === 0 ? monthYear[1] - 1 : monthYear[1];
    setMonthYear([month,year]);
  }

  const addMonth = () => {
    let month = monthYear[0] === 11 ? 0 : monthYear[0] + 1;
    let year = monthYear[0] === 11 ? monthYear[1] + 1 : monthYear[1];
    setMonthYear([month,year]);
  }

  return (
    <div className="date-picker" tabIndex="0" >
      {dates && 
        <div className="date-picker__header">
          <i className="fa-solid fa-caret-left date-picker__arrow" onClick={() => {subtractMonth()}}></i>
          <div className="date-picker__time-range"><span className="date-picker__month">{getMonthString(monthYear)}</span> {monthYear[1]}</div>
          <i className="fa-solid fa-caret-right date-picker__arrow" onClick={() => {addMonth()}}></i>
        </div>
      }
      <div className="date-picker__weekdays">
        <div className="date-picker__weekday">Sun</div>
        <div className="date-picker__weekday">Mon</div>
        <div className="date-picker__weekday">Tues</div>
        <div className="date-picker__weekday">Wed</div>
        <div className="date-picker__weekday">Thurs</div>
        <div className="date-picker__weekday">Fri</div>
        <div className="date-picker__weekday">Sat</div>
      </div>
      <div className="date-picker__dates">
        {dates && dates.map((date, i) => {
          let newDate = new Date(date);
          let today = new Date();
          return (
            <div key={`${date}-${i}`} 
            className={`date-picker__date-card${(newDate < today.setDate(today.getDate() - 1) || !date) ? ' date-picker__date-card--inactive' : ''}${`${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}` === `${activeDate.getDate()}/${activeDate.getMonth()}/${activeDate.getFullYear()}` ? ' date-picker__date-card--active' : ''}`}
            onClick={() => {
                if (newDate > today) {
                  setActiveDate(newDate);
                  setFieldValue(dateName, `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()}`)
                  closeCalendar();
                }
              }}
            >
              <div className="date-picker__date">
                {date ? date.getDate() : ""}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const TimePicker = (props) => {
  const {hourName, minName, daytimeName, className, submitting, onChange, values} = props;
  const validateHour = value => {
    let field = hourName || 'hour';
    if (parseInt(value) < 1) {
      onChange(field, '12');
    } else if (parseInt(value) > '12' || value.slice(0,1) === '0') {
      if (value.slice(-1) === '0') onChange(field, '12');
      else onChange(field, value.slice(-1));
    } else {
      onChange(field, `${parseInt(value)}`)
    }
  }
  const validateMin = value => {
    let field = minName || 'min';
    if (!/^[0-9]+$/i.test(value)) {
      onChange(field, '00');
    } else if (value.length > 2) {
      if (value.slice(1,) > 59) onChange(field, '59');
      else onChange(field, `${value.slice(1,)}`);
    } else if (value.length === 1) {
      onChange(field, `0${value}`);
    } else if (value > 59) {
      onChange(field, '00');
    }
  }

  return (
    <div className={`time-picker ${className}`}>
      <i className={`fa-solid fa-clock time-picker__field time-picker__clock`}></i>
      <input className="time-picker__field time-picker__hour" name={hourName || 'hour'} value={values[hourName || 'hour']} onChange={(e) => validateHour(e.target.value)}/>
      <div className={`time-picker__colon${submitting ? ' input-disabled' : ''}`}>:</div>
      <input className="time-picker__field time-picker__min" name={minName || 'min'} value={values[minName || 'min']} onChange={(e) => validateMin(e.target.value)} />
      <select className="time-picker__field time-picker__daytime" name={daytimeName || 'daytime'} value={values[daytimeName || 'daytime']} onChange={(e) => onChange(daytimeName || 'daytime', e.target.value)}>
        <option value="am">AM</option>
        <option value="pm">PM</option>
      </select>
    </div>
  )
  // Make a container for 3 divs hour/minute/daytime
  // Make an input for each
  // Onchange for any of them, use formikcontext to update end_time field
}

export const ColorPicker = (props) => {
  const [display, setDisplay] = useState(false);
  const [color, setColor] = useState(props.color);
  const { name, className, submitting, top } = props;
  const { setFieldValue } = useFormikContext();

  // useEffect(() => {
  //   if (display === true) {
  //     let popup = document.querySelector(`.color-selector__popup`);
  //     console.log(popup.getBoundingClientRect())
  //   }
  // }, [display])

  const colorToHex = (num) => {
    let hexadecimal = num.toString(16);
    return hexadecimal.length === 1 ? `0${hexadecimal}` : hexadecimal;
  }

  const convertRGBtoHex = (rgb) => {
    let hex = '#';
    for (let num of rgb.match(/[\d]+/g).slice(0,-1)) {
      hex+=colorToHex(parseInt(num));
    }
    return hex;
  }

  const RGBtoObject = (rgb) => {
    let rgbArr = rgb.match(/[\d]+/g);
    return {
      r: rgbArr[0],
      g: rgbArr[1],
      b: rgbArr[2],
      a: rgbArr[3]
    }
  }

  const toggleDisplay = () => {
    if (!submitting) {
      setDisplay(!display);
    }
  };

  const onCloseMethod = () => {
    setDisplay(false);
  };

  const onChangeMethod = (value) => {
    let newColor = `rgb(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    setColor(newColor);
    setFieldValue(name, newColor);
  };

  const popover = {
    position: "absolute",
    zIndex: "30",
  };
  if (top) popover.bottom = "24px";

  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };
  // amplifier__input amplifier__input--full-width
  return (
    <div className={className}>
      <label htmlFor={name}></label>
      <Field name={name} type="hidden" value={color} />
      <div className="color-selector" onClick={toggleDisplay}>
        <div style={{backgroundColor: color}} className="color-selector__display"></div>
        <span className="color-selector__hex">{convertRGBtoHex(color)}</span>
      </div>
      {display ? (
        <div className="color-selector__popup" style={popover}>
          <div style={cover} onClick={onCloseMethod} />
          <SketchPicker 
            color={RGBtoObject(color)} 
            onChange={onChangeMethod} 
          />
        </div>
      ) : null}
    </div>
  );
}

export const FormError = (props) => {
  const { className, inputName } = props;
  
  return (
    <div className={`form-error ${className}`}>
      <ErrorMessage name={inputName} />  
    </div>
  )
}
// Response handling
export const ResponseDisplay = () => {
  return (
    <div className="response-display"></div>
  )
}
export const responseHandler = (res, type, msg) => {
  let resDisplay = document.querySelector('.response-display');
  let currentRes = document.querySelector('.response-display__response-container');
  if (currentRes) {
    currentRes.remove();
  }
  let spaceBuffer = document.createElement('div');
  spaceBuffer.classList.add('response-display__space-buffer');
  let responseContainer = document.createElement('div');
  let responseText = document.createElement('div');
  responseText.classList.add('response-display__response');
  let closeWrapper = document.createElement('div');
  closeWrapper.classList.add('response-display__close-wrapper');
  let close = document.createElement('div');
  close.textContent = 'x';
  close.classList.add('response-display__close');
  close.addEventListener('click', () => {
    document.querySelector('.response-display__response-container').remove();
  })
  closeWrapper.append(close);
  let resClasses;
  if (type === 'success') {
    resClasses = ['response-display__response-container', 'response-display__response-container--success'];
  } else {
    resClasses = ['response-display__response-container', 'response-display__response-container--error'];
  }
  responseText.textContent = msg ? msg : `${res.headers['x-amplify-response']}`;
  responseContainer.classList.add(...resClasses);
  responseContainer.append(spaceBuffer, responseText,closeWrapper);
  resDisplay.append(responseContainer);
  setTimeout(() => {
    responseContainer.remove();
  }, 3000)
}

// Previews
export const ProductCard = (props) => {
  let title = props.title ? props.title : false;
  let price = props.price ? props.price : false;
  let html;
  if (props.styles) {
    const {text, font_color, points_color, font_size } = props.styles;
    if (text.includes('%points%')) {
      let textArr = text.split('%points%');
      html = <div className={`${props.classes}__product-points`} style={{fontSize: font_size, color: font_color}}>
          {textArr[0]}<span style={{fontSize: font_size, color: points_color, fontWeight: 'bold'}}>20 Points</span>{textArr[1]}
        </div>
    } else {
      html = <div className={`${props.classes}__product-points`} style={{fontSize: font_size, color: font_color}}>{text}</div>
    }
  }

  return (
    <div className={`${props.classes}__product-card`}>
      <i className={`fa-solid fa-shirt ${props.classes}__product-image`}></i>
      <CreateText chars={3} min={!title.min ? 5 : title.min} max={!title.max ? 10 : title.max} styles={!title.styles ? {margin: '0 5px 0 0'} : title.styles} classes={`${props.classes}__product-title`} />
      <CreateText chars={1} min={!price.min ? 10 : price.min} max={!price.max ? 11 : price.max} styles={!price.styles ? {height: '10px', padding: '0 5px 0 3px'} : price.styles} classes={`${props.classes}__product-price`}>$</CreateText>
      {props.status && props.styles &&
        <div className={`${props.classes}__product-points-wrapper`}>{html}</div>
      }
    </div>
  )
}

export const SearchPreview = (props) => {
  const {heading, close, endpoint, className} = props;
  const [results, setResults] = useState(false);
  const [picks, setPicks] = props.picks;
  const [newPicks, setNewPicks] = useState(picks);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const search = useRef(null);

  useEffect(() => {
    getResults();
  }, [])

  const getResults = (after, query) => {
    setFetching(true);
    setError(false);
    let params = {
      jwt: localStorage.getItem('_id')
    };
    if (after) {
      params.after = after;
    }
    if (query && query !== '') {
      params.search = query;
    }

    axios.get(
      `https://api.amplifyloyalty.com/app/stores/${endpoint}`,
      {params}
    ).then(res => {
      let resData = [];
      if (res.data.length === 0 && search) {
        setError(true);
      } else {
        if (endpoint === 'products') {
          resData = res.data.map(prod => {
            return {
              title: prod.node.title,
              admin_graphql_api_id: prod.node.id,
              featuredImage: prod.node.featuredImage,
              cursor: prod.cursor
            }
          })
        } else {
          resData = res.data;
        }
        if ((search && !after) || results === true || results.length === 0) {
          setResults([...resData]);
        } else {
          setResults([...results, ...resData]);
        }
      }
    }).catch(err => {
      console.log(err);
      setResults(false);
    }).finally(() => {
      setFetching(false);
    })
  }

  const handleSearch = (e) => {
    if (e.key.toLowerCase() === "enter") {
      setResults(false);
      getResults(false, search.current.value);
    }
  }

  const handlePicks = (pick) => {
    const picked = newPicks.filter(p => p.id === pick.id);
    if (picked.length > 0) {
      setNewPicks(newPicks.filter(p => p.id !== pick.id));
    } else {
      setNewPicks([...newPicks, pick]);
    }
  }

  const submit = () => {
    setPicks(newPicks);
    close(false);
  }

  return (
    <div className={`search-preview${className ? ` ${className}` : ''}`}>
      <div className="search-preview__header">
        <div className="search-preview__search-bar redemption-form__input-container">
          <i 
            className="fa-solid fa-magnifying-glass redemption-form__input-identifier redemption-form__input-identifier--value"
            onClick={() => handleSearch({key: 'enter'})}
          >
          </i>
          <input 
            ref={search} 
            type="text" 
            className="search-preview__search redemption-form__input redemption-form__input--value" 
            placeholder={`Search ${endpoint}`}
            onKeyDown={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div className="search-preview__results">
        {results.length > 0 && results.map((res, i) => {
          return (
            <div key={i} className="search-preview__result">
                <label className="search-preview__label">
                  {picks.filter(p => p.id === res.admin_graphql_api_id).length > 0 &&
                    <input 
                      className="search-preview__input" 
                      type="checkbox" 
                      name={i} 
                      onClick={() => handlePicks({title: res.title, id: res.admin_graphql_api_id, featuredImage: res.featuredImage ? res.featuredImage.url : null})} 
                      defaultChecked
                    />
                  }
                  {picks.filter(p => p.id === res.admin_graphql_api_id).length === 0 &&
                    <input 
                      className="search-preview__input" 
                      type="checkbox" 
                      name={i} 
                      onClick={() => handlePicks({title: res.title, id: res.admin_graphql_api_id, featuredImage: res.featuredImage ? res.featuredImage.url : null})} 
                    />
                  }
                  <div className="search-preview__content">
                    {res.featuredImage && <img src={res.featuredImage.url} alt={res.title} className="search-preview__img" />}
                    <div className="search-preview__title">{res.title}</div>
                  </div>
                </label>
            </div>
          )
        })}
        {error && 
          <div className="search-preview__no-results">No results.</div>
        }
        {fetching && <Loading className="search-preview__loading" />}
        {!fetching && results.length > 0 && results.length % 25 === 0 && 
          <div className="search-preview__load-more" onClick={() => getResults(results[results.length - 1].cursor, search.current.value ? search.current.value : '')}>Load More</div>
        }
      </div>
      <div className="search-preview__controls">
        <button type="button" className="search-preview__btn btn" onClick={() => close(false)}>Cancel</button>
        <button type="button" className='search-preview__btn btn gradient' onClick={() => submit()}>Add</button>
      </div>
    </div>
  )

}

export const PicksDisplay = (props) => {
  const {picks, setPicks} = props;

  return (
    <div className="picks-display">
      {picks && picks.map((pick, i) => {
        return (
          <div key={i} className="picks-display__pick">
            <div className="picks-display__delete" onClick={() => setPicks(picks.filter(p => p.id !== pick.id))}>x</div>
            {pick.featuredImage && <img className="picks-display__img" src={pick.featuredImage} alt={pick.title} />}
            <div className="picks-display__title">{pick.title}</div>
          </div>
        )
      })}
    </div>
  )
}

export const SimpleSearchPreview = (props) => {
  const {heading, close, endpoint, className, promotion} = props;
  const [results, setResults] = useState(false);
  const [formValues, setFormValues] = props.formValues;
  const tempPicks = formValues.items ? 
    formValues.items.ids : 
    promotion ? 
      formValues :
      [];
  const [newPicks, setNewPicks] = useState([...tempPicks]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const search = useRef(null);

  useEffect(() => {
    getResults();
  }, [])

  const getResults = (after, query) => {
    setFetching(true);
    setError(false);
    let params = {
      jwt: localStorage.getItem('_id')
    };
    if (after) {
      params.after = after;
    }
    if (query && query !== '') {
      params.search = query;
    }

    axios.get(
      `https://api.amplifyloyalty.com/app/stores/${endpoint}`,
      {params}
    ).then(res => {
      let resData = [];
      if (res.data.length === 0 && search) {
        setError(true);
      } else {
        if (endpoint === 'products') {
          resData = res.data.map(prod => {
            return {
              title: prod.node.title,
              admin_graphql_api_id: prod.node.id,
              featuredImage: prod.node.featuredImage,
              cursor: prod.cursor
            }
          })
        } else {
          resData = res.data.map(coll => {
            return {
              title: coll.node.title,
              admin_graphql_api_id: coll.node.id,
              featuredImage: coll.node.image,
              cursor: coll.cursor
            }
          })
        }
        if ((search && !after) || results === true || results.length === 0) {
          setResults([...resData]);
        } else {
          setResults([...results, ...resData]);
        }
      }
    }).catch(err => {
      console.log(err);
      setResults(false);
    }).finally(() => {
      setFetching(false);
    })
  }

  const handleSearch = (e) => {
    if (e.key.toLowerCase() === "enter") {
      setResults(false);
      getResults(false, search.current.value);
    }
  }

  const handlePicks = (pick) => {
    const picked = newPicks.filter(p => p.id === pick.id);
    if (picked.length > 0) {
      setNewPicks(newPicks.filter(p => p.id !== pick.id));
    } else {
      setNewPicks([...newPicks, pick]);
    }
  }

  const submit = () => {
    if (!promotion) {
      setFormValues({...formValues, items: {...formValues.items, ids: newPicks}});
    } else {
      setFormValues([...newPicks])
    }
    close(false);
  }

  return (
    <div className={`search-preview${className ? ` ${className}` : ''}`}>
      <div className="search-preview__header">
        <div className="search-preview__search-bar redemption-form__input-container">
          <i 
            className="fa-solid fa-magnifying-glass redemption-form__input-identifier redemption-form__input-identifier--value"
            onClick={() => handleSearch({key: 'enter'})}
          >
          </i>
          <input 
            ref={search} 
            type="text" 
            className="search-preview__search redemption-form__input redemption-form__input--value" 
            placeholder={`Search ${endpoint}`}
            onKeyDown={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div className="search-preview__results">
        {results.length > 0 && results.map((res, i) => {
          return (
            <div key={i} className="search-preview__result">
                <label className="search-preview__label">
                  {newPicks.filter(p => p.id === res.admin_graphql_api_id).length > 0 &&
                    <input 
                      className="search-preview__input" 
                      type="checkbox" 
                      name={i} 
                      onClick={() => handlePicks({title: res.title, id: res.admin_graphql_api_id, featuredImage: res.featuredImage ? res.featuredImage.url : null})} 
                      defaultChecked
                    />
                  }
                  {newPicks.filter(p => p.id === res.admin_graphql_api_id).length === 0 &&
                    <input 
                      className="search-preview__input" 
                      type="checkbox" 
                      name={i} 
                      onClick={() => handlePicks({title: res.title, id: res.admin_graphql_api_id, featuredImage: res.featuredImage ? res.featuredImage.url : null})} 
                    />
                  }
                  <div className="search-preview__content">
                    {res.featuredImage && <img src={res.featuredImage.url} alt={res.title} className="search-preview__img" />}
                    <div className="search-preview__title">{res.title}</div>
                  </div>
                </label>
            </div>
          )
        })}
        {error && 
          <div className="search-preview__no-results">No results.</div>
        }
        {fetching && <Loading className="search-preview__loading" />}
        {!fetching && results.length > 0 && results.length % 25 === 0 && 
          <div className="search-preview__load-more" onClick={() => getResults(results[results.length - 1].cursor, search.current.value ? search.current.value : '')}>Load More</div>
        }
      </div>
      <div className="search-preview__controls">
        <button type="button" className="search-preview__btn btn" onClick={() => close(false)}>Cancel</button>
        <button type="button" className='search-preview__btn btn gradient' onClick={() => submit()}>Add</button>
      </div>
    </div>
  )

}
export const SimplePicksDisplay = (props) => {
  const {formValues, setFormValues, disabled} = props;
  const deletePick = (id) => {
    if (!disabled) setFormValues({...formValues, items: {...formValues.items, ids: [...formValues.items.ids.filter(item => item.id !== id)]}})
  }
  return (
    <div className="picks-display">
      {formValues.items.ids && formValues.items.ids.map((pick, i) => {
        return (
          <div key={i} className="picks-display__pick">
            <div className="picks-display__delete" onClick={() => deletePick(pick.id)}>x</div>
            {pick.featuredImage && <img className="picks-display__img" src={pick.featuredImage} alt={pick.title} />}
            <div className="picks-display__title">{pick.title}</div>
          </div>
        )
      })}
    </div>
  )
}
export const WarningIcon = (props) => {
  const {size, color} = props;

  return (
    <div className="warning" style={{ backgroundColor: color, height: size, width: size, borderRadius: size}}>
      <div className="warning__icon" style={{fontSize: size * .75}}>!</div>
    </div>
  )
}

export const EditMenu = (props) => {
  const {className, options, disabled} = props;
  const [open, setOpen] = useState(false);

  return (
    <div className={`edit-menu${className ? ` ${className}` : ''}`}>
      <div className="edit-menu__bar" onClick={() => setOpen(true)}>
        <div className="edit-menu__bullet-container">
          <div className="edit-menu__bullet">.</div>
        </div>
        <div className="edit-menu__bullet-container">
          <div className="edit-menu__bullet">.</div>
        </div>
        <div className="edit-menu__bullet-container">
          <div className="edit-menu__bullet">.</div>
        </div>
        <div className="edit-menu__bullet-container">
        </div>
      </div>
      {open && 
        <EditPopup options={options} setOpen={setOpen} disabled={disabled}/>
      }
      
    </div>
  )
}

const EditPopup = (props) => {
  const {options, setOpen, disabled} = props;
  const onClickFunc = (e) => {
    const menu = document.querySelector(`.edit-menu__options`);
    if (e.currentTarget !== menu) {
      document.removeEventListener('click', onClickFunc);
      setOpen(false);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      document.addEventListener('click', onClickFunc);
    }, 0);

    // Cleanup the event listener when the component unmounts
    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', onClickFunc);
    };
  },[])

  return (
    <div className="edit-menu__options">
      {options.map((option, i) => {
        if (!option.disabled) {
          return (
            <div key={i} className={`edit-menu__option${disabled ? ' edit-menu__option--disabled' : ''}`}>
              <div onClick={() => {
                if (!disabled) option.action();
              }}>{option.text}</div>
            </div>
          )
        }
        else return false;
      })}
    </div>
  )
}

export const DropDownFilter = (props) => {
  const {options, className, active, setActive, dates, disabled} = props;
  const [open, setOpen] = useState(false);
  const [prevSelection, setPrevSelection] = useState(options[0].text);
  const menuId = (Math.random() * 1000000).toFixed(0);

  return (
    <div className={`date-filter${className ? ` ${className}` : ''}`}>
      {dates && <DateRange 
        action={options.filter(opt => opt.text === 'Custom Range')[0].action} 
        prevSelection={prevSelection}
        setPrevSelection={setPrevSelection}
        setActive={setActive}
        disabled={disabled}
      />}
      <div className={`date-filter__input${typeof active === 'number' ? ' date-filter__input--sort' : ''}`} onClick={() => setOpen(true)}>
        <div className={`date-filter__input-text${typeof active === 'number' ? ' date-filter__input-text--sort' : ''}`}>
          {typeof active === 'number' ? <Arrow className="arrow-icon--filter-text" direction={active} /> : active}
        </div>
        {typeof active !== 'number' &&
          <i className="fa-solid fa-caret-down date-filter__input-chevron"></i>
        }
      </div>
      {open && !disabled && 
        <DropDownMenu 
          options={options} 
          active={active} 
          setActive={setActive} 
          setOpen={setOpen} 
          setPrevSelection={setPrevSelection}
          menuId={menuId}
        />
      }
    </div>
  )
}

const DropDownMenu = (props) => {
  const {options, active, setActive, setOpen, setPrevSelection, menuId} = props;
  const onClickFunc = (e) => {
    const menu = document.querySelector(`.date-filter__menu--${menuId}`);
    if (e.currentTarget !== menu) {
      document.removeEventListener('click', onClickFunc);
      setOpen(false);
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      document.addEventListener('click', onClickFunc);
    }, 0);

    // Cleanup the event listener when the component unmounts
    return () => {
      clearTimeout(timer);
      document.removeEventListener('click', onClickFunc);
    };
  },[])

  return (
    <div className={`date-filter__menu date-filter__menu--${menuId}`}>
      {options.map(opt => {
        return (
          <div 
            key={opt.text} 
            onClick={() => {
              if (opt.text !== 'Custom Range') {
                opt.action();
              }
              setPrevSelection(opt.text);
              setActive(opt.text);
              setOpen(false)
            }} 
            className={`date-filter__menu-option${active === opt.text ? ' date-filter__menu-option--active' : ''}`}
          >
            {typeof opt.text === 'number' ? <Arrow direction={opt.text} /> : opt.text}
          </div>
        )
      })}
    </div>
  )
}

export const DateRange = (props) => {
  const {action, prevSelection, setPrevSelection, setActive, future, disabled } = props;
  let today = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [monthYear, setMonthYear] = useState([today.getMonth(), today.getFullYear()]);
  const [dates, setDates] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (prevSelection !== 'Custom Range') {
      let now = new Date();
      now.setHours(0,0,0,0);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      switch (prevSelection) {
        case "Last 7 Days":
          now.setDate(today.getDate() - 7);
          setStartDate(now)
          setEndDate(yesterday);
          break;
        case "Last 14 Days":
          now.setDate(today.getDate() - 14);
          setStartDate(now)
          setEndDate(yesterday);
          break;
        case "Last 30 Days":
          now.setDate(today.getDate() - 30);
          setStartDate(now)
          setEndDate(yesterday);
          break;
        case "Last 90 Days": 
          now.setDate(today.getDate() - 90);
          setStartDate(now)
          setEndDate(yesterday);
          break;
        case "Last 180 Days": 
          now.setDate(today.getDate() - 180);
          setStartDate(now)
          setEndDate(yesterday);
          break;
        case "This Year":
          setStartDate(new Date(`1/1/${today.getFullYear()}`));
          setEndDate(new Date(`12/31/${today.getFullYear()}`));
          break;
        case "Last Year":
          setStartDate(new Date(`1/1/${today.getFullYear()-1}`));
          setEndDate(new Date(`12/31/${today.getFullYear()-1}`));
          break;
        case "All Time":
          setStartDate(new Date(`1/1/2023`));
          setEndDate(new Date('12/31/2100'));
          break;
        default:
          now.setDate(today.getDate() - 90);
          setStartDate(now)
          setEndDate(yesterday);
    }
    }
  }, [prevSelection])
  // Set the state of these based on what the previous range was someone was looking at (7 days, 30 days, etc.)
  
  const dpId = useRef(`dp-${Math.round(Math.random() * 1000)}`);
  
  const monthDays = [
    31,
    28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31
  ];

  useEffect(() => {
    getNewDates(monthYear);
  }, [monthYear])

  const getNewDates = ([month, year]) => {
    let monthDay = monthDays[month];
    if (year % 4 === 0 && month === 1) monthDay = 29;
    let dateArr = [];
    let startDate = new Date(year, month, 1);
    let dayBuffer = startDate.getDay();
    if (dayBuffer !== 0) {
      for (let i = 0; i < dayBuffer; i++) {
        dateArr.push("");
      }
    }
    for (let i = 0; i < monthDay; i++) {
      let newDate = new Date(year, month, i+1)
      dateArr.push(newDate);
    }
    setDates(dateArr);
  }

  return (
    <div id={dpId.current} className={`date-range-selector date-range-selector__input-container`} onClick={() => { if(!open){setOpen(true)} }} >
      <i className={`fa-solid fa-calendar-days date-picker__calendar`}></i>
      <div className="date-range-selector__input-container">
        <input type="text" name="start_date" className={`date-range-selector__input date-range-selector__input date-filter__input`} readOnly={true} value={typeof startDate === 'string' ? startDate : `${startDate.getMonth()+1}/${startDate.getDate()}/${startDate.getFullYear()}`} />
      </div>
      <div className="date-range-selector__divider-container">
        <div className="date-range-selector__divider">---</div>
      </div>
      <div className="date-range-selector__input-container">
        <input type="text" name="end_date" className={`date-range-selector__input date-range-selector__input date-filter__input`} readOnly={true} value={typeof endDate === 'string' ? endDate : !endDate ? '' : `${endDate.getMonth()+1}/${endDate.getDate()}/${endDate.getFullYear()}`}/>
      </div>
      {open && !disabled && 
        <DoubleCalendar 
          dates={dates} 
          monthYear={monthYear} 
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setMonthYear={setMonthYear}
          setOpen={setOpen}
          action={action}
          setActive={setActive}
          setPrevSelection={setPrevSelection}
          future={future}
        />
      }
    </div>
  )

}

const DoubleCalendar = (props) => {
  const {
    dates, 
    monthYear, 
    startDate, 
    setStartDate,
    endDate,
    setEndDate,
    setMonthYear, 
    setOpen,
    action,
    setActive,
    setPrevSelection,
    future
  } = props;
  const [picking, setPicking] = useState(false);
  const [hoverDate, setHoverDate] = useState(false);
  const firstEndDate = useRef(endDate);
  const iStart = useRef(startDate);
  const iEnd = useRef(endDate);

  const onClickFunc = (e) => {
    const menu = document.querySelector('.date-range-selector');
    if (!e.composedPath().includes(menu)) {
      if (!document.querySelector('.date-range-selector__input[name="end_date"]').value) {
        setStartDate(iStart.current);
        setEndDate(iEnd.current);
      }
      if (endDate === firstEndDate.current) { 
        setMonthYear([endDate.getMonth(), endDate.getFullYear()])
      }
      document.removeEventListener('click', onClickFunc);
      setOpen(false);
    } 
  }

  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', onClickFunc);
    }, 250);
  }, [])

  const getMonthString = ([month, year]) => {
    const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
    return formatter.format(new Date(year, month));
  }

  const subtractMonth = () => {
    let month = monthYear[0] === 0 ? 11 : monthYear[0] - 1;
    let year = monthYear[0] === 0 ? monthYear[1] - 1 : monthYear[1];
    setMonthYear([month,year]);
  }

  const addMonth = () => {
    let month = monthYear[0] === 11 ? 0 : monthYear[0] + 1;
    let year = monthYear[0] === 11 ? monthYear[1] + 1 : monthYear[1];
    setMonthYear([month,year]);
  }

  return (
    <div className="date-picker date-picker--double" tabIndex="0" >
      {dates && 
        <div className="date-picker__header">
          <i className="fa-solid fa-caret-left date-picker__arrow" onClick={() => {subtractMonth()}}></i>
          <div className="date-picker__time-range"><span className="date-picker__month">{getMonthString(monthYear)}</span> {monthYear[1]}</div>
          <i className="fa-solid fa-caret-right date-picker__arrow" onClick={() => {addMonth()}}></i>
        </div>
      }
      <div className="date-picker__weekdays">
        <div className="date-picker__weekday">Sun</div>
        <div className="date-picker__weekday">Mon</div>
        <div className="date-picker__weekday">Tues</div>
        <div className="date-picker__weekday">Wed</div>
        <div className="date-picker__weekday">Thurs</div>
        <div className="date-picker__weekday">Fri</div>
        <div className="date-picker__weekday">Sat</div>
      </div>
      <div className="date-picker__dates">
        {dates && dates.map((date, i) => {
          let isFuture;
          let thisDate = new Date(date);
          if (!future && thisDate > new Date()) {
            isFuture = true;
          } 
          return (
            <div key={`${date}-${i}`} 
            onMouseEnter={() => {
              if (picking) {
                setHoverDate(date);
              }
             }}
             onMouseLeave={() => {
              if (picking) {
                setHoverDate(false);
              }
             }}
            className={`date-picker__date-card${!date || isFuture ? ' date-picker__date-card--inactive' : ''}${(date === startDate || (date >= startDate && date <= endDate) || (hoverDate && (date <= hoverDate && date >= startDate))) ? ' date-picker__date-card--active' : ''}`}
            onClick={() => {
              if (!isFuture) {
                if (!picking || (picking && date < startDate)) {
                  setStartDate(date);
                  setEndDate(false);
                  setPicking(true);
                } else {
                  setActive('Custom Range');
                  setPrevSelection('Custom Range');
                  const endWithHours = new Date(date);
                  endWithHours.setHours(23,59,59,59);
                  setEndDate(endWithHours);
                  setPicking(false);
                  action(startDate, endWithHours);
                  setOpen(false);
                }
                setHoverDate(false);
              }
            }}
            >
              <div className="date-picker__date">
                {date ? date.getDate() : ""}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const TablePagination = (props) => {
  const {className, options, docType, previous, next} = props;

  return (
    <div className={`table-pagination${className ? ` ${className}` : ''}`}>
      {options &&
        <>
          <div className="table-pagination__docs-info">
            {options.limit * options.page < options.totalDocs ? options.limit * options.page : options.totalDocs} of {options.totalDocs} {docType}
          </div>
          <div className="table-pagination__navigation">
            <div 
              className={`table-pagination__navigate table-pagination__navigate--previous${!options.hasPrevPage ? ' table-pagination__navigate--disabled' : ''}`}
              onClick={() => {
                if (options.hasPrevPage) {
                  previous();
                }
              }}
            >
              {`< Prev`}
            </div>
            <div className="table-pagination__divider">|</div>
            <div className="table-pagination__navigation-info">Page {options.page} of {options.totalPages}</div>
            <div className="table-pagination__divider">|</div>
            <div 
              className={`table-pagination__navigate table-pagination__navigate--next${!options.hasNextPage ? ' table-pagination__navigate--disabled' : ''}`}
              onClick={() => {
                if (options.hasNextPage) {
                  next();
                }
              }}
            >
              {`Next >`}
            </div>
          </div>
          
        </>
      }
    </div>
  )
}

export const BasicTable = (props) => {
  const {data, keys, className, header} = props;
  const names = Object.keys(keys);
  return (
    <div className={`basic-table${className ? ` ${className}` : ''}`}>
      {header && <div className="basic-table__title">{header}</div>}
      <div className="basic-table__header">
        {Object.values(keys).map(key => {
          return (
            <div className="basic-table__heading" style={{width: `${100 / names.length}%` }}>{key.name}</div>
          )
        })}
      </div>
      <div className="basic-table__body">
        {data && data.map(entry => {
          return (
            <div className="basic-table__row">
              {names.map((name) => {
                return (
                  <div className="basic-table__cell" style={{width: `${100 / names.length}%` }}>{keys[name].format(entry[name])}</div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const CustomTable = (props) => {
  const {
    data, 
    setData,
    metrics,
    description,
    objectName,
    endpoint,
    pageInfo, 
    setPageInfo,
    createObject,
    deleteObject,
    editObject,
    duplicateObject
  } = props;
  const moneyFormat = Intl.NumberFormat('en-US', {style: "currency", currency: 'USD', maximumFractionDigits: 0});
  const numberFormat = Intl.NumberFormat('en-US');

  return (
    <section className='giveaways-container centralize'>
      <div className="giveaways-header">
        <p>{description}</p>
        <button className="giveaways-header__create-new btn gradient" onClick={() => createObject()}>NEW {objectName.toUpperCase()}</button>
      </div>
      <div className="giveaways-table">
        <TableFilters 
          data={data} 
          setData={setData} 
          pageInfo={pageInfo} 
          setPageInfo={setPageInfo}
          endpoint={endpoint}
          docType={objectName}
        >
          <div className="giveaways-table__header">
            <div className="giveaways-table__heading">Promotion</div>
            <div className="giveaways-table__metrics">
              {Object.keys(metrics).map((key, i) => {
                return (
                  <div key={i} className="giveaways-table__heading">{metrics[key].name}</div>
                )
              })}
              <div className="giveaways-table__heading"></div>
            </div>
          </div>
          <div className="giveaways-table__body">
            {!data && <Loading className="giveaways-table__loading" />}  
            {data.length === 0 && 
              <div className="giveaways-table__empty">
                <div className="giveaways-table__text-wrapper">None to show at this time.</div>
              </div>
            }
            {data.length > 0 &&
              data.map((doc) => {
                let latestDate = new Date(
                  doc.status === "draft" ? 
                    doc.last_modified :
                    doc.execution_time
                );
                let dateInfo = `${latestDate.getMonth()+1}/${latestDate.getDate()}/${latestDate.getFullYear()} at ${latestDate.getHours() > 12 ? latestDate.getHours() % 12 : latestDate.getHours() === 0 ? 12 : latestDate.getHours()}:${latestDate.getMinutes() > 9 ? "" : "0"}${latestDate.getMinutes()} ${latestDate.getHours() < 12 ? 'AM' : 'PM'}`;
                return (
                  <div key={doc._id} className="giveaways-table__row">
                    <div className="giveaways-table__cell giveaways-table__info">
                      <div className="giveaways-table__title">
                        <h3 className="giveaways-table__name">
                          <a className="giveaways-table__name-link" href={`/app/${endpoint}/${doc._id}${doc.status !== 'draft' ? '/reporting' : ''}`}>{doc.name}</a>
                        </h3>
                      </div>
                      <div className="giveaways-table__execution-info">{`${titleCaseText(doc.status)} - ${dateInfo}`}</div>
                    </div>
                    <div className="giveaways-table__metric-data">
                      {Object.keys(metrics).map((key, i) => {
                        const format = metrics[key].format;
                        if (format === 'money')
                          return (
                            <div key={i} className="giveaways-table__metric">{moneyFormat.format(doc[key] || 0)}</div>
                          )
                        else 
                          return (
                            <div key={i} className="giveaways-table__metric">{numberFormat.format(doc[key] || 0)}</div>
                          )
                      })}
                      <div className="giveaways-table__metric">
                        <EditMenu className="promotions-table__edit" options={[
                          {
                            text: doc.status === 'draft' ? 'Edit' : 'Analytics',
                            action: () => editObject(`/app/giveaways/${doc._id}${doc.status !== 'draft' ? '/reporting' : ''}`)
                          },
                          {
                            text: 'Duplicate',
                            action: () => duplicateObject(doc)
                          },
                          {
                            text: 'Delete',
                            action: () => deleteObject(doc),
                            disabled: doc.status !== "draft" || false
                          }
                        ]}/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </TableFilters>
      </div>
    </section>
  )
}

const TableFilters = (props) => {
  const {data, setData, pageInfo, setPageInfo, docType, disabled, endpoint} = props;
  const [activeDate, setActiveDate] = useState('All Time');
  const [activeSort, setActiveSort] = useState('Execution Time');
  const [activeDirection, setActiveDirection] = useState(-1);
  const [activeStatus, setActiveStatus] = useState('Any Status');
  const prevSearch = useRef('');
  const prevData = useRef(data);

  const getData = (range, start, end, sort, direction, page, status, search) => {
    setData(false);
    let newSort;
    if (sort.includes(' ')) {
      newSort = sort.toLowerCase().split(' ').join('_'); 
    }
    axios.get(`https://api.amplifyloyalty.com/app/stores/${endpoint}`,
      {
        params: {
          jwt: localStorage.getItem('_id'),
          range: range,
          start_date: start,
          end_date: end,
          sort: newSort || sort,
          direction: direction,
          page: page || 1,
          status: status === 'Any Status' ? null : status,
          search: search || document.querySelector('.promotions-table-filters__input').value
        }
      }).then(res => {
        let info = {...res.data}
        delete info.data;
        setPageInfo(info)
        setData(res.data.docs || []);
      }).catch(err => {
        console.log(err);
      });
  }

  const search = (value) => {
    prevData.current = data;
    setTimeout(() => {
      let currentValue = document.querySelector('.promotions-table-filters__input').value;
      if (currentValue === value && value !== prevSearch.current) {
        let [start, end] = checkForRange();
        getData(activeDate, start, end, activeSort, activeDirection, null, activeStatus, value);
        prevSearch.current = value;
      } else {
        setData(prevData.current);
      }
    }, 1000)
  }

  const checkForRange = () => {
    let dates = document.querySelectorAll('.date-range-selector__input');
    if (dates.length === 2) {
      return [...dates].map(input => input.value);
    }
  }

  return (
    <>
      <div className="promotions-table-filters">
        <div className="promotions-table-filters__filter-wrapper">
          <input type="text" className="promotions-table-filters__input" placeholder={`Search ${docType}`} onChange={(e) => search(e.target.value)}/>
          <DropDownFilter 
              className="promotions-table-filters__status-filter"
              active={activeStatus}
              setActive={setActiveStatus}
              disabled={disabled}
              options={[
                {
                  text: 'Any Status',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'execution_time', activeDirection, null, 'Any Status');
                  }
                },
                {
                  text: 'Executed',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'execution_time', activeDirection, null, 'executed');
                  }
                },
                {
                  text: 'Scheduled',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'execution_time', activeDirection, null, 'scheduled');
                  }
                },
                {
                  text: 'Draft',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'execution_time', activeDirection, null, 'draft');
                  }
                }
              ]}
            />
        </div>
        <div className="promotions-table-filters__modifiers">
          <div className="promotions-table-filters__filter-wrapper">
            <label className="promotions-table-filters__sort-label" htmlFor="sort">Sort by</label>
            <DropDownFilter 
              className="promotions-table-filters__date-filter"
              active={activeSort}
              setActive={setActiveSort}
              disabled={disabled}
              options={[
                {
                  text: 'Execution Time',
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, 'execution_time', activeDirection, null, activeStatus);
                  }
                }
              ]}
            />
            <DropDownFilter 
              className="promotions-table-filters__sort-direction"
              active={activeDirection}
              setActive={setActiveDirection}
              disabled={disabled}
              options={[
                {
                  text: -1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, -1, null, activeStatus);
                  }
                },
                {
                  text: 1,
                  action: () => {
                    let [start, end] = checkForRange();
                    getData(activeDate, start, end, activeSort, 1, null, activeStatus);
                  }
                }
              ]}
            />
          </div>
          <DropDownFilter 
            className="promotions-table-filters__filter-wrapper"
            active={activeDate}
            setActive={setActiveDate}
            disabled={disabled}
            options={[
              {
                text: 'All Time',
                action: () => getData('All Time', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 90 Days',
                action: () => getData('Last 90 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last 180 Days',
                action: () => getData('Last 180 Days', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'This Year',
                action: () => getData('This Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Last Year',
                action: () => getData('Last Year', null, null, activeSort, activeDirection, null, activeStatus)
              },
              {
                text: 'Custom Range',
                action: (start, end) => getData('Custom Range', start, end, activeSort, activeDirection, null, activeStatus)
              }
            ]}
            dates={true}
          />
        </div>
      </div>
      {props.children}
      {pageInfo.totalPages > 1 && 
        <TablePagination 
          options={pageInfo} 
          docType="promotions"
          next={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.nextPage, activeStatus);
            }
          }
          previous={
            () => {
              let [start, end] = checkForRange();
              getData(activeDate, start, end, activeSort, activeDirection, pageInfo.prevPage, activeStatus);
            }
          }
        />
      }
    </>
  )
}

export const ToolTip = (props) => {
  const { message, height, width, bottom } = props;
  return (
    <div className="tooltip">
      <div className={`tooltip__message-container${bottom ? '--reverse' : ''}`}>
        <div className="tooltip__message" style={{height, width}}>{message}</div>
        <div className="tooltip__triangle"></div>
      </div>
      <div className="tooltip__icon">?</div>
    </div>
  )
}

export const ConfirmationPopup = (props) => {
  const { headline, description, action, setActive } = props;

  return (
    <div className="confirmation-popup--overlay">
      <div className="confirmation-popup">
        <div className="confirmation-popup__headline">{headline}</div>
        <div className="confirmation-popup__description">{description}</div>
        <div className="confirmation-popup__buttons">
          <button className="confirmation-popup__cancel gradient" onClick={() => setActive(false)}>Cancel</button>
          <button className="confirmation-popup__confirm" onClick={action}>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export const CouponForm = (props) => {
  const {coupon, setCreatingCoupon, submit, disabled, i} = props;
  const [valueSign, setValueSign] = useState(false);
  const [searchPreview, setSearchPreview] = useState(false);
  const [errors, setErrors] = useState(false);
  const [formValues, setFormValues] = useState(typeof coupon === 'object' ? coupon : {
    name: '',
    reward_type: 'currency_off',
    description: '',
    expires: false,
    days_available: 7,
    minimum: false,
    minimum_requirement: 0,
    value: 0,
    usage_limit: true,
    usage_limit_value: 1,
    shipping_threshold: false,
    shipping_threshold_value: 0,
    items: {
      restriction_type: 'all_orders',
      ids: []
    }
  });

  useEffect(() => {
    // Show extra fields unless Free Shipping type
    switch (formValues.reward_type) {
      case "currency_off":
        setValueSign("$");
        break;
      case "percent_off":
        setValueSign("%");
        break;
      case "free_shipping":
        setValueSign(false);
        break;
      default: 
        setValueSign("$");
    }

  }, [formValues.reward_type]);

  const validate = async () => {
    let errs = [];
    let nameRe = /[\w\d]+/g;
    if (!nameRe.exec(formValues.name)) {
      errs.push({field: 'name', message: 'Name must contain numbers or letters.'});
    }
    if (formValues.reward_type !== 'free_shipping' && formValues.value <= 0) {
      errs.push({field: 'value', message: 'Value must be greater than 0.'});
    }
    if (formValues.items.restriction_type !== 'all_orders' && formValues.items.ids.length === 0) {
      errs.push({
        field: 'restriction_type', 
        message: `You must select at least one ${formValues.items.restriction_type === 'collections' ? 'collection' : 'product'}.`
      });
    }
    if (formValues.usage_limit && (formValues.usage_limit_value <= 0 || !parseInt(formValues.usage_limit_value))) {
      errs.push({field: 'usage_limit_value', message: 'Must be a positive whole number.'});
    }
    if (formValues.expires && (formValues.days_available <= 0 || !parseInt(formValues.days_available))) {
      errs.push({field: 'expires', message: 'Must be a positive whole number.'});
    }
    if (formValues.minimum && (formValues.minimum_requirement <= 0 || !parseFloat(formValues.minimum_requirement))) {
      errs.push({field: 'minimum', message: 'Must be a positive number.'});
    }
    if (formValues.shipping_threshold && (formValues.shipping_threshold_value <= 0 || !parseFloat(formValues.shipping_threshold_value))) {
      errs.push({field: 'shipping_threshold', message: 'Must be a positive number.'});
    }
    // console.log(nameRe.exec(formValues.name));
    return errs;
  }

  const submitForm = async () => {
    // validate formValues
    let errs = await validate();
    if (errs.length === 0) {
      submit(formValues);
    } else {
      console.log({errs});
      setErrors(errs);
    }
  }

  return (
    <div className="flex-popup-overlay">
      {searchPreview && 
        <SimpleSearchPreview 
          close={setSearchPreview}
          heading={formValues.items.restriction_type === 'collections' ? 'Add collections' : 'Add products'}
          formValues={[formValues,setFormValues]}
          endpoint={formValues.items.restriction_type === 'collections' ? 'collections' : 'products'}
        />
      }
      {!searchPreview && 
        <section className="redemption-form-container">
          <div className="redemption-form">
            <div className="redemption-form__heading">
              <h3 className='redemption-form__title'>Coupon Reward</h3>
              <div className="redemption-form__editing">
                {/* {isEditing && <div className="redemption-form__button"><i className="fa-solid fa-trash redemption-form__delete" onClick={() => deleteRedemption()}></i></div>} */}
              </div>
            </div>
            <div className="redemption-form__fields">
              <div className="redemption-form__field">
                <label htmlFor="name">Name</label>
                <div className="redemption-form__input-container">
                  <input className="redemption-form__input" disabled={disabled} name="name" value={formValues.name} onChange={(e) => setFormValues({...formValues, name: e.target.value })}/>
                </div>
                {errors && errors.filter(e => e.field === 'name').length > 0 && 
                  <div className="redemption-form__error">{errors.filter(e => e.field === 'name')[0].message}</div>
                }
              </div>
              <div className="redemption-form__field">
                <label htmlFor="type">Type</label>
                <div className="redemption-form__input-container">
                  <select name="reward_type" disabled={disabled} className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.reward_type} onChange={(e) => setFormValues({...formValues, reward_type: e.target.value, items: { restriction_type: "all_orders", ids: []}})}>
                    <option value="currency_off">Amount off</option>
                    <option value="percent_off">Percent off</option>
                    <option value="free_shipping">Free Shipping</option>
                  </select>
                </div>
              </div>
              {valueSign && 
                <div className="redemption-form__extra-fields">
                  <div className="redemption-form__field">
                    <label htmlFor="value">Value</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">{valueSign}</div>
                      <input className="redemption-form__input redemption-form__input--value" disabled={disabled} name="value" type="number" value={formValues.value} onChange={(e) => setFormValues({...formValues, value: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'value')[0].message}</div>
                    }
                  </div>
                  {/* <FormError className="vip-form__error-message" inputName="value" /> */}
                </div>
              }
              {formValues.reward_type !== 'free_shipping' &&
                <div className="redemption-form__field">
                  <label htmlFor="restriction_type">Applies to</label>
                  <div className="redemption-form__input-container">
                    <select name="restriction_type" disabled={disabled} className="redemption-form__input redemption-form__input--select redemption-form__input--type" value={formValues.items.restriction_type} onChange={(e) => setFormValues({...formValues, items: { restriction_type: e.target.value, ids: []}})}>
                      <option value="all_orders">All orders</option>
                      <option value="collections">Specific collections</option>
                      <option value="products">Specific products</option>
                    </select>
                  </div>
                  {errors && errors.filter(e => e.field === 'restriction_type').length > 0 && 
                    <div className="redemption-form__error">{errors.filter(e => e.field === 'restriction_type')[0].message}</div>
                  }
                </div>
              }
              {formValues.items.restriction_type !== 'all_orders' && 
                <div className="extra-fields">
                  {!disabled && <div className="vip-form__add-bonus" onClick={() => setSearchPreview(true)}>+ {formValues.items.restriction_type === 'collections' ? "Collection" : "Products"}</div>}
                  <SimplePicksDisplay formValues={formValues} setFormValues={setFormValues} disabled={disabled} />
                </div>
              }
              {formValues.reward_type === 'free_shipping' &&
                <>
                  <div className="redemption-form__field redemption-form__field--left">
                    <input className="redemption-form__input redemption-form__input--small" disabled={disabled} type="checkbox" name="shipping_threshold" value={formValues.shipping_threshold} checked={formValues.shipping_threshold} onChange={(e) => setFormValues({...formValues, shipping_threshold: e.target.checked})}/>
                    <label className="redemption-form__label--small" htmlFor="shipping_threshold">Max shipping value</label>
                  </div>
                  {formValues.shipping_threshold && 
                    <div className="extra-fields">
                      <div className="redemption-form__field">
                        <label className='redemption-form__sub-label' htmlFor="shipping_threshold_value">Up to</label>
                        <div className="redemption-form__input-container">
                          <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                          <input disabled={disabled} className="redemption-form__input redemption-form__input--value" name="shipping_threshold_value" type="number" value={formValues.shipping_threshold_value} onChange={(e) => setFormValues({...formValues, shipping_threshold_value: parseFloat(e.target.value)})}/>
                        </div>
                        {errors && errors.filter(e => e.field === 'shipping_threshold').length > 0 && 
                          <div className="redemption-form__error">{errors.filter(e => e.field === 'shipping_threshold')[0].message}</div>
                        }
                      </div>
                      <div className="error-message">
                      </div>
                    </div>
                  }
                </>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="usage_limit" value={formValues.usage_limit} checked={formValues.usage_limit} onChange={(e) => setFormValues({...formValues, usage_limit: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="usage_limit">Usage limit</label>
              </div>
              {formValues.usage_limit && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="usage_limit_value">Can be used</label>
                    <div className="redemption-form__input-container">
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--points" name="usage_limit_value" type="number" value={formValues.usage_limit_value} onChange={(e) => setFormValues({...formValues, usage_limit_value: parseInt(e.target.value)})} />
                      <div className="redemption-form__input-identifier">times</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'usage_limit_value').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'usage_limit_value')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="expires" value={formValues.expires} checked={formValues.expires} onChange={(e) => setFormValues({...formValues, expires: e.target.checked})}/>
                <label className="redemption-form__label--small" htmlFor="expires">Set expiration</label>
              </div>
              {formValues.expires && 
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="days_available">Expires after</label>
                    <div className="redemption-form__input-container">
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--points" name="days_available" type="number" value={formValues.days_available} onChange={(e) => setFormValues({...formValues, days_available: parseInt(e.target.value)})}/>
                      <div className="redemption-form__input-identifier">days</div>
                    </div>
                    {errors && errors.filter(e => e.field === 'expires').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'expires')[0].message}</div>
                    }
                  </div>
                </div>
              }
              <div className="redemption-form__field redemption-form__field--left">
                <input disabled={disabled} className="redemption-form__input redemption-form__input--small" type="checkbox" name="minimum" value={formValues.minimum} checked={formValues.minimum} onChange={(e) => setFormValues({...formValues, minimum: e.target.checked })}/>
                <label className="redemption-form__label--small" htmlFor="minimum">Minimum order value</label>
              </div>
              {formValues.minimum &&
                <div className="extra-fields">
                  <div className="redemption-form__field">
                    <label className='redemption-form__sub-label' htmlFor="minimum_value">Subtotal at least</label>
                    <div className="redemption-form__input-container">
                      <div className="redemption-form__input-identifier redemption-form__input-identifier--value">$</div>
                      <input disabled={disabled} className="redemption-form__input redemption-form__input--value" name="minimum_requirement" type="number" value={formValues.minimum_requirement} onChange={(e) => setFormValues({...formValues, minimum_requirement: parseFloat(e.target.value)})}/>
                    </div>
                    {errors && errors.filter(e => e.field === 'minimum').length > 0 && 
                      <div className="redemption-form__error">{errors.filter(e => e.field === 'minimum')[0].message}</div>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="redemption-form__field redemption-form__button-container">
              <button className="redemption-form__cancel-button btn" type="button" onClick={() => {setCreatingCoupon(false)}}>Cancel</button>
              {!disabled && 
                <button className="redemption-form__submit-button btn gradient" type="submit" onClick={submitForm}>Save</button>
              }
            </div>
          </div>
        </section>
      }
    </div>
  )
}

export const fillDates = async (data, start, end, emptyValue) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let dateMap = []
  for await (let item of data) {
    let splitDate = item.Date.split('-');
    let formatDate = new Date(splitDate[0], parseInt(splitDate[1])-1, splitDate[2]); // converted to client timezone
    if (!dateMap.includes(formatDate.getTime())) {
      dateMap.push(formatDate.getTime());
    }
  };
  // the dates are being returned in UTC and then converted to client timezone here
  // convert the days to the correct timezone first?
  const newData = [...data];
  for (let d = new Date(startDate), i = 0; d <= endDate; d.setDate(d.getDate() + 1), i++) {
    if (!dateMap.includes(d.getTime())) {
      newData.push({Date: `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`, ...emptyValue});
    } 
  }
  newData.sort((a, b) => new Date(a.Date) - new Date(b.Date))
  return newData;
}

export const convertToTimezone = (date) => {
  let timeZone = JSON.parse(localStorage.getItem('_id')).timezone;
  const zoneDate = toZonedTime(date, timeZone);
  const newDate = format(zoneDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
  // console.log(newDate);
  return newDate;
}

export const convertToClientTimezone = (dateString) => {
  console.log(dateString)
  const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Parse the dateString into a Date object with timezone information
  const parsedDate = parse(dateString, 'M/d/yyyy HH:mm', new Date());
  console.log({parsedDate})
  // Convert parsedDate to the client timezone
  const clientZoneDate = toZonedTime(parsedDate, clientTimezone);
  console.log({clientZoneDate})
  const formattedDate = format(clientZoneDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: clientTimezone });
  console.log({formattedDate});
  return formattedDate;
};